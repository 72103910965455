import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import merge from 'deepmerge';
import storeConfig from 'store';
import { AuthProtect } from 'common/enums';
import { IEndpoint } from 'common/interfaces';
import apiConfig from './apiConfig';

export * from './apiParams';
export * from './apiEndpoints';

const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

const apiFetch = async (api: IEndpoint) => {
  const { store } = storeConfig;
  const headers: AxiosRequestHeaders = {};
  const params = api.params || {};
  const baseURL = apiConfig.baseURL;

  if (api.authorization === AuthProtect.REQUIRED) {
    const { token } = store.getState();
    if (token) {
      params.access_token = token.authorization;
    } else {
      // Throw error message: missing token
    }
  }

  let response: AxiosResponse;
  try {
    response = await axios({
      ...merge.all([apiConfig, api, { baseURL, headers, params }]),
      validateStatus: (status: number) => {
        return status < 500;
      },
    });
  } catch (e) {
    console.log('apiFetch catch error >>>', e);
    throw {
      message: genericErrorMessage,
    };
  }
  console.log('response.status >>>', response.status);
  if (Number(response.status) === 401 || Number(response.status) === 403) {
    localStorage.clear();
    window.location.href = '/login';
  }
  return {
    status: response.status,
    data: response.data,
  };
};

export default apiFetch;
