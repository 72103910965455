import { Dispatch } from 'redux';
import moment from 'moment';
import { IEmergencyRequest } from 'interfaces/EmergencyRequest';
import { QueryState } from 'common/enums';
import apiFetch, {
  createEmergencyRequest,
  sendNotificationRequestAll,
} from 'services/apiFetch';
import { AppState } from 'store/state/AppState';
import { pageActions } from 'store/actions';
import { RequestForm } from 'helpers/forms/requestForm';

export const requestPayload = (
  userId: number,
  formData: RequestForm
): Partial<IEmergencyRequest> => {
  const { regions, resources, requestDetails, emailBody } = formData;
  const { requestNumber, ...details } = requestDetails;
  const payload: Partial<IEmergencyRequest> = {
    requestId: requestNumber,
    userId,
    details: {
      ...details,
      ...resources,
      emailBody,
      // TODO: Create a common date utils formatter
      date: moment(requestDetails.date).format('YYYY-MM-DD'),
      regions: regions.reduce((arr: any, r: any) => {
        const counties = r.counties?.filter((c: any) => c.checked);
        if (counties.length) {
          return arr.concat({
            counties: counties.map((c: any) => c.county),
            region: r.region,
          });
        }
        return arr;
      }, []),
    },
  };
  return payload;
};

const create =
  (formData: RequestForm) =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    dispatch(pageActions.update({ state: QueryState.AWAIT }));

    const { token } = getState();
    const payload = requestPayload(token?.id as number, formData);

    await apiFetch(createEmergencyRequest(payload))
      .then(async ({ data }) => {
        const requestNumber = data?.requestId;
        dispatch(pageActions.update({ newRequestNumber: requestNumber }));
        const endpoint = sendNotificationRequestAll({
          requestId: requestNumber,
        });
        await apiFetch(endpoint)
          .catch((e) =>
            console.log('catch error sendNotificationRequestAll:', e)
          )
          .finally(() => {
            dispatch(pageActions.update({ state: QueryState.CREATED }));
          });
      })
      .catch((error) => {
        console.error('thunk create error:', error);
        dispatch(pageActions.update({ state: QueryState.FAIL }));
      });
  };

export default create;
